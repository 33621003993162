.NavbarMenu {
    z-index: 1;
    font-size: calc(3.815 * var(--contentScale));
    font-family: font;
    width: calc(var(--base) * 3.375);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.80);
    margin-left: calc(var(--base)/7.5);
    border-radius: calc(var(--base)/2.25);
    opacity: 0;
    transition: opacity 0.08s ease-in;
}

.NavbarMenu.show {
    opacity: 1;
    backdrop-filter: blur(4px);
    padding-right: 2px; /* NEEDS FIXING */
}

.NavbarMenu.clear {
    display: none;
}

.NavbarMenuItem {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: calc(var(--base) / 1.25);
    width: 100%;
    background: none;
    background-size: contain;
    border: solid;
    border-style: solid;
    color: black;
    border-radius: 128px;
    cursor: pointer;
    margin-bottom: calc(var(--base) / 7.5);
}

.NavButton:last-child {
    margin-bottom: 0;
}