button {
    -webkit-appearance: none;
    /* Safari and iOS */
    -moz-appearance: none;
    /* Firefox */
    appearance: none;
    /* Other browsers */
    outline: none;
    /* Remove the default outline */
    background-color: transparent;
    /* Set the background color to transparent */
}

body {
    margin: 0
}

h1 {
    margin: 0;
}

h2 {
    margin: 0;
}

h3 {
    margin: 0;
}

p {
    margin: 0;
}

a {
    color: black;
}