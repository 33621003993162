.CTAButton {
    align-self: center;
    font-family: font;
    font-weight: bolder;
    width: clamp(5%, 100%, 50vh);
    background: none;
    border: solid;
    border-style: solid;
    color: black;
    padding: calc(0.75*var(--base)) 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 0px;
    cursor: pointer;
    border-color: black;
    border-radius: 128px;
    font-size: calc(3.815*var(--contentScale));
}

.NavigationButton {
    display: flex;
    align-items: center;
    height: calc(var(--base)/1.25);
    width: calc(var(--base)/1.25);
    padding: 0;
  
    border-radius: 128px;
    cursor: pointer;
    margin-left: calc(var(--base)/7.5);
    
    background-clip: padding-box;
    background-origin: border-box;
    transition: 0.08s ease-in;
    
    border: solid;
    color: black;
    border-radius: 128px;
}

.NavigationButton.menuOpen {
    background: none;
    border: none;
}