.DescriptionContainer {
    align-self: flex-start;
    padding-top: calc(1*var(--contentScale));
    font-size: calc(1.563*var(--contentScale));
}

.TextContentContainer {
    font-family: font;
    height: 100%;
    display: inherit;
    flex-direction: column;
    padding: calc(0.5*var(--base));
    padding-top: 0;
    padding-bottom: 0;
    justify-content: start;
}

.TextContentContainer:last-child {
    padding-bottom: calc(0.5*var(--base));;
}

.FooterContentContainer {
    font-family: font;
    font-size: calc(1.563 * var(--contentScale));
    text-decoration: underline;
    margin-bottom: calc(0.1*var(--base));
    display: inherit;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
}

.PageTitleing {
    display: flex;
    flex-direction: column;
    padding: calc(0.5*var(--base));
    padding-top: 0;
    padding-bottom: 0;
}

.InsetTextContainer {
    padding-left: calc(0.5*var(--base));
    padding-right: calc(0.5*var(--base));
}