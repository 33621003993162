.PageWrapper {
    background-image: url('../graphics/Background.svg');
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
}

.PageContent {
    padding-top: calc(0.25*var(--base));
    min-height: 84.25vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: clamp(5%, 100%, 100vh);
    margin: 0 auto;
}

.Navbar {
    display: flex;
    height: var(--base);
    justify-content: center;
    align-items: center;
    position: relative;
}

.Footer {
    max-width: calc(15*var(--base));
    padding-left: calc(0.5*var(--base));
    padding-right: calc(0.5*var(--base));
    height: var(--base);
    display: flex;
    flex-direction: column;
    color: black;
    align-self: center;
    box-sizing: border-box;
    width: 100%;
}