.BulletItem {
    padding: calc(0.25 * var(--base));
    padding-left: 0;
    font-size: calc(2.441 * var(--contentScale));
    display: flex;
    flex-direction: column;
    border-bottom: solid;
}

.BulletItem:last-child {
    border-bottom: none;
}

.BulletItemTitleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BulletItemTitle {
    font-family: font;
    font-size: calc(3.052 * var(--contentScale));
}

.BulletItemContent {
    font-family: font;
    margin-top: 10px;
    margin-left: 10px;
}

.BulletListContainer {
    padding-top: calc(0.25 * var(--base));
}