:root {
    --base: 7vh;
    --contentScale: clamp(2px, 1.75vw, 1vh);
  }

@font-face {
  font-family: font;
  src: url('../fonts/main/Questrial-Regular.ttf');
}
@font-face {
  font-family: logofont;
  src: url('../fonts/logo/PlayfairDisplaySC-Regular.ttf');
}