.CTAContainer {
    height: 100%;
    display: inherit;
    flex-direction: column;
    padding: calc(0.5*var(--base));
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;
}

.ButtonContainer {
    flex: 1;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-evenly;
  }