.NavbarLogoContainer {
    height: var(--base);
    display: inherit;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  
  .NavbarLogo {
    font-size: calc(var(--contentScale)*2.5);
    margin-right: var(--base);
    font-family: logofont;
  }