.MainTitle {
    font-family: font;
    padding-top: calc(5.96*var(--contentScale));
    padding-right: calc(0.5*var(--base));
    font-size: calc(3.052*var(--contentScale));
}

.SecondaryTitle {
    font-family: font;

    padding-right: calc(1.5 * var(--base));
    font-size: calc(2.441 * var(--contentScale));
}

.ThirdTitle {
    font-family: font;
    padding-top: calc(0.5 * var(--base));
    padding-right: calc(1.5 * var(--base));
    font-size: calc(2.441 * var(--contentScale));
}

.TitleDescription {
    font-size: calc(2.441 * var(--contentScale));
}

.TextContent {
    font-family: font;
    font-size: calc(1.953 * var(--contentScale));
}